import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthStore from "@/store/auth-store";
import { IOrgAdmin } from "@/types/OrgAdmin";
import WorkForceService from "@/shared/application/work-force-service-proxy";

@Options({
  name: "RegisterOrgAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const orgAdminSchema = yup.object().shape({
      firstName: yup
        .string()
        .required("First name is required!")
        .max(50, "Must be maximum 20 characters!"),
      lastName: yup
        .string()
        .required("Last name is required!")
        .max(50, "Must be maximum 20 characters!"),
      workEmail: yup
        .string()
        .required(
          "Please enter your work email, we do not accept personal emails."
        )
        .email("Email is invalid!")
        .max(100, "Must be maximum 100 characters!"),
      jobTitle: yup
        .string()
        .required("Please enter your job title.")
        .max(50, "Must be maximum 50 characters!"),
      departmentName: yup
        .string()
        .required("Please enter your department!")
        .max(50, "Must be maximum 50 characters!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      orgAdminSchema,
    };
  },
  computed: {
    loggedIn() {
      return this.authStore.isSignedIn();
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/admin/home");
    }
  },
})
export default class RegisterOrgAdmin extends Vue {
  protected authStore = AuthStore;
  protected workForceService: WorkForceService = new WorkForceService();

  orgId = 0;
  message = "";

  created(): void {
    if (this.authStore.getRegOrgToken()) {
      this.orgId = +this.authStore.getRegOrgToken()!;
    } else {
      this.orgId = +"";
    }
  }

  loading = false;
  orgAdminRegister(user: IOrgAdmin): void {
    this.message = "";
    this.loading = true;
    user.orgId = this.orgId;

    this.workForceService
      .postOrPut<void>(
        "/account/registerOrgAdmin",
        user as IOrgAdmin,
        undefined
      )
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          this.authStore.removeRegOrgToken();
          this.$router.push({
            name: "continueEmail",
          });
        } else if (response.isSuccess == false) {
          this.loading = false;
          this.message = response.errors;
        } else {
          this.loading = false;
          const errors = response.errors.errors;
          for (const key in errors) {
            this.message += key + ": " + errors[key][0] + "<br>";
          }
        }
      });
  }
}
