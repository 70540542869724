<template>
  <div class="my-lg-5 my-md-7 my-2">
    <div class="row align-items-center">
      <div class="col col-lg-6 d-none d-lg-block text-center">
        <img
          class="p-5"
          src="@/assets/register-personal-details.svg"
          alt="people with thumbs up signs"
        />
      </div>

      <div class="col col-md-9 col-lg-6 mx-auto">
        <!--                Step-2-->
        <div class="card d-lg-none bg-light shadow-sm py-3 mb-3">
          <div class="col d-flex d-none align-items-center">
            <!--                  Step Number-->
            <div
              class="mr-3 px-3 py-2 shadow-sm align-self-center badge badge-primary"
              style="font-size: 150%; border-radius: 10px"
            >
              2
            </div>
            <!--                  Step Number Text-->
            <div class="text-left">
              <span class="h4">Step 2</span>

              <!--                    Step Details-->
              <div class="text-muted">Personal Details</div>
            </div>
          </div>
        </div>

        <!--        Content-->
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="pb-3 mb-4 border-bottom">
              <div class="h4">Register - Your details</div>
            </div>
            <Form
              @submit="orgAdminRegister"
              v-slot="meta"
              :validation-schema="orgAdminSchema"
            >
              <div class="form-group">
                <label class="font-weight-bold">First name</label>
                <input-field icon="id-card">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      :class="meta.errors.firstName ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="firstName" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">Last name</label>
                <input-field icon="id-card">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      :class="meta.errors.lastName ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="lastName" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  Work email
                  <span
                    data-tooltip="Verification link will be sent to this email"
                  >
                    <font-awesome-icon
                      class="ml-1 text-muted"
                      icon="info-circle"
                    />
                  </span>
                </label>
                <input-field icon="at">
                  <template v-slot:input>
                    <Field
                      type="email"
                      name="workEmail"
                      placeholder="Email"
                      :class="meta.errors.workEmail ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="workEmail" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">Your job title</label>
                <input-field icon="id-card">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="jobTitle"
                      placeholder="Job Title"
                      :class="meta.errors.jobTitle ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="jobTitle" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">Your department name</label>
                <input-field icon="building">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="departmentName"
                      placeholder="Department"
                      :class="meta.errors.departmentName ? 'is-invalid' : ''"
                    />
                    <ErrorMessage
                      name="departmentName"
                      class="invalid-feedback"
                    />
                  </template>
                </input-field>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-sm-12 col-md-8">
                  <button class="btn-primary btn-lg-cus" :disabled="loading">
                    <span class="font-weight-bold">Continue </span>
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                  </button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./RegisterOrgAdmin.ts"></script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.step-styles {
  max-width: 200px;
}
.error-feedback {
  color: red;
}
</style>
